exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-originals-jsx": () => import("./../../../src/pages/originals.jsx" /* webpackChunkName: "component---src-pages-originals-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-contributor-jsx": () => import("./../../../src/templates/contributor.jsx" /* webpackChunkName: "component---src-templates-contributor-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-legal-page-jsx": () => import("./../../../src/templates/legal-page.jsx" /* webpackChunkName: "component---src-templates-legal-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-podcast-jsx": () => import("./../../../src/templates/podcast.jsx" /* webpackChunkName: "component---src-templates-podcast-jsx" */),
  "component---src-templates-preview-component-preview-jsx": () => import("./../../../src/templates/preview/component-preview.jsx" /* webpackChunkName: "component---src-templates-preview-component-preview-jsx" */),
  "component---src-templates-reads-main-page-jsx": () => import("./../../../src/templates/readsMainPage.jsx" /* webpackChunkName: "component---src-templates-reads-main-page-jsx" */),
  "component---src-templates-reports-jsx": () => import("./../../../src/templates/reports.jsx" /* webpackChunkName: "component---src-templates-reports-jsx" */),
  "component---src-templates-special-landing-page-jsx": () => import("./../../../src/templates/special-landing-page.jsx" /* webpackChunkName: "component---src-templates-special-landing-page-jsx" */),
  "component---src-templates-special-section-page-jsx": () => import("./../../../src/templates/special-section-page.jsx" /* webpackChunkName: "component---src-templates-special-section-page-jsx" */),
  "component---src-templates-topic-jsx": () => import("./../../../src/templates/topic.jsx" /* webpackChunkName: "component---src-templates-topic-jsx" */),
  "component---src-templates-video-jsx": () => import("./../../../src/templates/video.jsx" /* webpackChunkName: "component---src-templates-video-jsx" */)
}

